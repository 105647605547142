<script>
export default {
  emits: ["remove"],
  props: {
    commentFiles: {
      type: Array,
      default: [],
    },
    showImages: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    deleteUpload(index) {
      this.$emit("remove", index);
    },
  },
};
</script>
<template>
  <div class="row mt-2" v-if="commentFiles && commentFiles.length > 0">
    <div
      class="col-lg-6 col-md-4"
      v-for="(file, index) in commentFiles"
      :key="index"
    >
      <div class="attachment-card">
        <div class="d-flex align-items-center gap-2">
          <div class="">
            <div class="avatar-sm" v-if="file.type === 'png'">
              <img src="@/assets/images/extensions/png.png" alt="PNG" />
            </div>
            <div class="avatar-sm" v-else-if="file.type === 'pdf'">
              <img src="@/assets/images/extensions/pdf.png" alt="PDF" />
            </div>
            <div class="avatar-sm" v-else-if="file.type === 'csv'">
              <img src="@/assets/images/extensions/csv.png" alt="CSV" />
            </div>
            <div class="avatar-sm" v-else-if="file.type === 'jpg'">
              <img src="@/assets/images/extensions/jpg.png" alt="JPG" />
            </div>
            <div class="avatar-sm" v-else-if="file.type === 'jpeg'">
              <img src="@/assets/images/extensions/jpg.png" alt="JEPG" />
            </div>
            <div class="avatar-sm" v-else-if="file.type === 'doc'">
              <img src="@/assets/images/extensions/doc.png" alt="DOC" />
            </div>
            <div class="avatar-sm" v-else-if="file.type === 'docx'">
              <img src="@/assets/images/extensions/docx.png" alt="DOCX" />
            </div>
            <div class="avatar-sm" v-else-if="file.type === 'ppt'">
              <img src="@/assets/images/extensions/ppt.png" alt="PPT" />
            </div>
            <div class="avatar-sm" v-else-if="file.type === 'pptx'">
              <img src="@/assets/images/extensions/pptx.png" alt="PPTX" />
            </div>
            <div class="avatar-sm" v-else-if="file.type === 'xls'">
              <img src="@/assets/images/extensions/xls.png" alt="XLS" />
            </div>
            <div class="avatar-sm" v-else-if="file.type === 'xlsx'">
              <img src="@/assets/images/extensions/xlsx.png" alt="XLSX" />
            </div>
          </div>
          <div class="">
            <a href="javascript:void(0);">{{ file.name || "no file name" }}</a>
            <p class="mb-0">{{ file.size || "MB" }}</p>
          </div>
        </div>
        <a @click="deleteUpload(index)" class="text-danger">
          <feather-icon size="18" icon="TrashIcon" />
        </a>
      </div>
    </div>
  </div>
</template>
